import da from "../locales/da.json"
import en from "../locales/en.json"
export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en_US",
  fallbackLocale: "en_US",
  messages: {
    da_DK: da,
    en_US: en,
  },
}))
